<template>
  <business-bank-form />
</template>

<script>
import BusinessBankForm from "@/components/facilities/BusinessBankForm";

export default {
  components: {
    BusinessBankForm,
  },
};
</script>
