<template>
  <a-spin :spinning="loading">
    <div class="col-md-12">
      <div class="font-size-36 font-weight-bold text-dark mb-4">
        Bank Account Details
      </div>

      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <facility-bank-details-form-item
          :disabled="!canUpdateBankInformation"
        />

        <!-- SUBMIT BTN -->
        <a-button
          type="primary"
          htmlType="submit"
          size="large"
          class="text-center"
          :loading="loading"
        >
          <strong>Update Bank Details</strong>
        </a-button>
      </a-form>
    </div>
  </a-spin>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import FacilityBankDetailsFormItem from "@/components/facilities/FacilityBankDetailsFormItem";
import ProfileMixin from "@/mixins/Profile";

import {
  MISC_GET_COUNTRIES,
  MISC_GET_BANKS,
  MISC_GET_BANKS_BRANCHES,
  UPDATE_TOUR_SIGHT_BANK_INFORMATION,
} from "@/store/actions";

export default {
  mixins: [ProfileMixin],
  components: {
    FacilityBankDetailsFormItem,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
    };
  },
  computed: {
    ...mapGetters("misc", [
      "getCountriesList",
      "getBanksForCountry",
      "getBankBranches",
    ]),
    ...mapGetters("auth", ["currentUser", "userMenus"]),
    permissions() {
      return this.getValueFromSource(this.currentUser, "permissions", {});
    },
    canUpdateBankInformation() {
      return this.getValueFromSource(
        this.permissions,
        "can_update_bank_information",
        false
      );
    },
  },
  async created() {
    try {
      this.loading = true;

      await this.getCountries();
      await this.getBanksOfCountry(
        this.getValueFromSource(this.account, "bank.country_code")
      );

      this.form.setFieldsValue({
        bank: {
          country_code: this.getValueFromSource(
            this.account,
            "bank.country_code"
          ),
          bank_id: this.getValueFromSource(this.account, "bank.bank_id"),
          account_number: this.getValueFromSource(
            this.account,
            "bank.account_number"
          ),
        },
      });
    } catch (error) {
      this.$notification.error({ message: "Error fetching initial data" });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("misc", {
      getCountries: MISC_GET_COUNTRIES,
      getBanksOfCountry: MISC_GET_BANKS,
      getBranchesOfBank: MISC_GET_BANKS_BRANCHES,
    }),
    ...mapActions("tourSight", {
      updateBankDetails: UPDATE_TOUR_SIGHT_BANK_INFORMATION,
    }),
    getBankName(bank_id) {
      const bank = this.getBanksForCountry.find((b) => b.id === bank_id);
      if (bank) {
        return bank.name;
      } else {
        return "";
      }
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.updateBankAccount(values);
        } else {
          this.$notification.error({
            message:
              "Form validation failed. Please correct errors in form before submiting",
          });
        }
      });
    },
    async updateBankAccount(values) {
      try {
        console.log("values", values);
        this.loading = true;

        await this.updateProfileBankInformation({
          ...values.bank,
          bank_name: this.getBankName(values.bank.bank_id),
        });

        this.$notification.success({
          message: "Bank details updated successfully",
        });
      } catch (error) {
        this.$notification.error({
          message: "Error saving bank details: ", // error.response.data.message.toString(),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
